<template>
  <div id="inspectionSafetyId">
    <div class="page-container pb-0 mb-2">
      <div id="filter-custom">
        <h3
          class="header"
        >
          Bộ lọc
        </h3>
        <b-row>
          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Doanh nghiệp<span class="required"></span></label>
              <v-select
                :reduce="label => label.id"
                label="name"
                :options="dataComboboxBusiness || []"
                :placeholder="'Doanh nghiệp'"
                @input="filterBusiness"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            md="4"
            xl="4"
          >
            <b-form-group>
              <label
                for="basicInput"
              >Trạng thái<span class="required"></span></label>
              <v-select
                :reduce="label => label.statusLicenseAccrediation"
                label="statusLicenseAccrediationString"
                :options="dataStatusFilter || []"
                :placeholder="'Trạng thái'"
                @input="filterStatus"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </div>
    </div>

    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :arrayExecl="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideExportFile="false"
        :hideDowload="false"
        :hideAdd="false"
        :hideImportFile="false"
        class="custtom-header"
        @clickDelete="deleteItems"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã người dùng -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ $t(props.row.code) }}</span>
            </b-badge>
          </span>
          <!-- ngày cấp -->
          <span v-else-if="props.column.field == 'dateLicense'">
            {{ props.row.dateLicense | formatDateToDDMM }}
          </span>
          <!-- ngày hết hiệu lực -->
          <span v-else-if="props.column.field == 'dateExpired'">
            {{ props.row.dateExpired | formatDateToDDMM }}
          </span>
          <!-- statusString : Trạng thái  -->
          <span
            v-else-if="props.column.field == 'status' "
          >
            <b-badge
              pill
              :variant="statusVariant(props.row.statusLicenseAccrediation)"
              class="border-status"
            >
              <span> {{ props.row.statusLicenseAccrediationString }}</span>

            </b-badge>

          </span>
          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span v-if="!['RejectLicense', 'Revoke'].includes(props.row.statusLicenseAccrediation)">
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    v-b-tooltip.hover.top="'Xem đơn'"
                    icon="EyeIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="['PendingLicensing'].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdf(props.row.id)"
                >
                  <span>{{ 'Đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động kiểm định' }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['Licensing','Renew', 'Extend' ].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdfAccreditation(props.row.id)"
                >
                  <span>{{ 'Giấy chứng nhận kiểm định' }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['PendingLicensing','PendingRenew', 'PendingExtend' ].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdfEquipment(props.row.id)"
                >
                  <span>{{ 'Danh mục thiết bị, dụng cụ phục vụ kiểm định' }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['PendingLicensing','PendingRenew', 'PendingExtend' ].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdfDocument(props.row.id)"
                >
                  <span>{{ 'Danh mục tài liệu phục vụ kiểm định' }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['PendingLicensing','PendingRenew', 'PendingExtend' ].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdfInspector(props.row.id)"
                >
                  <span>{{ 'Danh mục phân công kiểm định viên' }}</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="['PendingRenew', 'PendingExtend' ].includes(props.row.statusLicenseAccrediation)"
                  @click="openModalSeePdfRenewExtend(props.row.id)"
                >
                  <span>{{ 'Đơn đề nghị gia hạn/ cấp lại giấy chứng nhận điều kiện hoạt động kiểm định' }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
            <feather-icon
              v-b-tooltip.hover.top="'Xem file đính kèm'"
              icon="FileTextIcon"
              size="18"
              @click="openModalDownFile(props.row.id)"
            />
            <span v-if="!['RejectLicense', 'Revoke'].includes(props.row.statusLicenseAccrediation)">
              <b-dropdown
                variant="link"
                boundary="viewport"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="18"
                    class="text-body"
                  />
                </template>
                <b-dropdown-item
                  v-if="props.row.statusLicenseAccrediation === 'PendingLicensing'"
                  @click="showAcceptAction(props.row.id)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span>Duyệt cấp phép</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.statusLicenseAccrediation === 'PendingLicensing'"
                  @click="showRejectAction(props.row.id)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span>Từ chối cấp phép</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="props.row.statusLicenseAccrediation === 'PendingRenew'"
                  @click="showRenewLicense(props.row.id)"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span>Duyệt cấp lại</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="['PendingExtend'].includes(props.row.statusLicenseAccrediation)"
                  @click="showExtendLicense(props.row.id)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span>Duyệt gia hạn</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="['Licensing', 'Extend', 'Renew'].includes(props.row.statusLicenseAccrediation)"
                  @click="showRevokeLicense(props.row.id)"
                >
                  <feather-icon
                    icon="CornerDownLeftIcon"
                    class="mr-50"
                  />
                  <span>Thu hồi</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- Duyệt cấp lại -->
      <modal-accept-paper
        :id="modalAcceptPaper"
      />

      <!--Cấp phép chứng nhận hoạt động kiểm định -->
      <modal-accept-action
        :id="modalAction"
        @handleAcceptLicense="handleAcceptLicense"
      />

      <!-- Từ chối duyệt cấp phép -->
      <modal-reject
        :id="modalReject"
        @handleReject="handleReject"
      />

      <!-- Gia hạn giấy kiểm định -->
      <modal-extend-license
        :id="modalExtendLicense"
        @handleExtendLicense="handleExtendLicense"
      />

      <!-- Cấp lại giấy kiểm định -->
      <modal-renew-license
        :id="modalRenewLicense"
        @handleRenewLicense="handleRenewLicense"
      />

      <!-- Thu hồi giấy kiểm định -->
      <modal-revoke-license
        :id="modalRevokeLicense"
        @handleRevoke="handleRevoke"
      />

      <!-- modal downfile -->
      <modal-down-file
        :id="modalDownFile"
        :dataDownloadFile="dataDownloadFile"
        :totalRecordDownloadFile="totalRecordDownloadFile"
        :queryParams="queryParams"
        @searchFile="searchFile"
        @handlePageClickPage="handlePageClickPage"
      />

      <!-- modal xem file pdf -->
      <modal-see-pdf
        :id="modalSeePdf"
        :pdfsrc="pdfsrc"
        @downloadFile="downloadFilePdf"
      />

      <!-- modal xóa người dùng-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown, VBTooltip,
  BDropdownItem,
} from 'bootstrap-vue'

import { formatDateToDDMM } from '@core/utils/filter'
import VSelect from 'vue-select'
import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalAcceptPaper from './components/ModalAcceptPaper.vue'
import ModalAcceptAction from './components/ModalAcceptAction.vue'
import ModalReject from './components/ModalReject.vue'
import ModalExtendLicense from './components/ModalExtendLicense.vue'
import ModalRenewLicense from './components/ModalRenewLicense.vue'
import ModalRevokeLicense from './components/ModalRevokeLicense.vue'
import ModalDownFile from './components/ModalDownFile.vue'
import ModalSeePdf from './components/ModalSeePdf.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import DataFilter from '../constants/DataFilter'

export default {
  components: {
    VSelect,
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    ModalAcceptPaper,
    ModalAcceptAction,
    VBTooltip,
    ModalReject,
    ModalExtendLicense,
    ModalRenewLicense,
    ModalRevokeLicense,
    ModalDownFile,
    ModalSeePdf,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      dataStatusFilter: DataFilter.dataStatus,
      dataDownloadFile: [],
      dataComboboxBusiness: [],
      totalRecord: 10,
      dataTable: [],
      columns: [
        {
          label: 'TÊN DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'ĐỊA CHỈ',
          field: 'address',
          sortable: false,
        },
        {
          label: 'Số chứng nhận',
          field: 'numberLicense',
          sortable: false,
        },
        {
          label: 'NGÀY CẤP',
          field: 'dateLicense',
          sortable: false,
        },
        {
          label: 'NGÀY HẾT HIỆU LỰC',
          field: 'dateExpired',
          sortable: false,
        },
        {
          label: 'SỐ LẦN CẤP',
          field: 'count',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'status',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          tdClass: 'actiontd',
          thClass: 'actionth',
          sortable: false,
          width: '250px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        bussinessId: null,
        status: null,
        key: null,
        pageSize: 10,
        pageNumber: 1,
      },
      queryParams: {
        licenseAccredidationId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalDetailUser: 'comfirm-show-file',
      modalAcceptPaper: 'comfirm-accept',
      modalAction: 'comfirm-action',
      modalReject: 'confirm-reject',
      modalExtendLicense: 'confirm-extend-license',
      modalRenewLicense: 'confirm-renew-license',
      modalRevokeLicense: 'confirm-revoke-license',
      modalDownFile: 'comfirm-download-license',
      modalSeePdf: 'comfirm-pdf-license',
      deleteIds: [],
      showBtnMultiDelete: false,
      licenseAccreditationId: null,
      totalRecordDownloadFile: 0,
      statusFileName: null,
      pdfsrc: null,
      modeType: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        NonLiscening: 'secondary',
        PendingLicensing: 'warning',
        Licensing: 'primary',
        RejectLicense: 'danger',
        PendingRenew: 'warning',
        PendingExtend: 'warning',
        Revoke: 'danger',
        Extend: 'success',
        Renew: 'primary',
      }
      return status => statusColor[status]
    },
  },
  created() {
    this.fetchDataInspectionSafety(this.urlQuery)
    this.fetchComboboxBusiness()
  },
  methods: {
    // Danh sách file để thêm
    async fetchDownloadFile(queryParams) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_FILE_DOWNLOAD, {
        params: queryParams,
      })
      this.dataDownloadFile = data.data.pageLists
      this.totalRecordDownloadFile = data.data.totalRecord
      this.$hideLoading()
    },

    // Mở modal danh sách file để download
    async openModalDownFile(id) {
      this.queryParams.licenseAccredidationId = id
      await this.fetchDownloadFile(this.queryParams)
      this.$bvModal.show(this.modalDownFile)
    },

    // Mở modal Tạo đơn đề nghị cấp giấy chứng nhận đủ điều kiện hoạt động kiểm định
    openModalSeePdf(id) {
      this.modeType = 'require'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-require-license'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục thiết bị, dụng cụ phục vụ kiểm định
    openModalSeePdfEquipment(id) {
      this.modeType = 'equipment'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-equipment'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục tài liệu phục vụ kiểm định
    openModalSeePdfDocument(id) {
      this.modeType = 'document'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-document'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo danh mục phân công kiểm định viên
    openModalSeePdfInspector(id) {
      this.modeType = 'inspector'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation-inspector'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo giấy chứng nhận kiểm định
    openModalSeePdfAccreditation(id) {
      this.modeType = 'accreditation'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-license-accreditation'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Mở modal Tạo đơn đề nghị gia hạn/ cấp lại giấy chứng nhận điều kiện hoạt động kiểm định
    openModalSeePdfRenewExtend(id) {
      this.modeType = 'renewExtend'
      this.licenseAccreditationId = id
      this.urlApi = '/LicenseAccreditation/file-require-renew-extend'
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const blob = new Blob([response.data])
        const objectUrl = URL.createObjectURL(blob)
        this.pdfsrc = objectUrl
      })
      this.$bvModal.show(this.modalSeePdf)
    },

    // Tải file pdf
    downloadFilePdf() {
      switch (this.modeType) {
        case 'require':
          this.nameFilePdf = 'DonDeNghiXacNhanDuDieuKienKiemDinh.pdf'
          break
        case 'equipment':
          this.nameFilePdf = 'DanhSachDungCuThietBiPhucVuKiemDinh.pdf'
          break
        case 'document':
          this.nameFilePdf = 'DanhSachTaiLieuPhucVuKiemDinh.pdf'
          break
        case 'inspector':
          this.nameFilePdf = 'DanhSachPhanCongKiemDinhVien.pdf'
          break
        case 'accreditation':
          this.nameFilePdf = 'GiayChungNhanKiemDinh.pdf'
          break
        case 'renewExtend':
          this.nameFilePdf = 'DonDeNghiGiaHan,CapLaiKiemDinh.pdf'
          break
        default:
          break
      }
      axiosApiInstance({
        url: this.urlApi, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { licenseAccreditationId: this.licenseAccreditationId },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', this.nameFilePdf) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // lấy danh sách khi tìm kiếm file
    searchFile(val) {
      if (val != null) {
        this.queryParams.pageNumber = 1
        this.queryParams.key = val
        this.fetchDownloadFile(this.queryParams)
      } else {
        this.queryParams.pageNumber = 1
        this.queryParams.key = ''
        this.fetchDownloadFile(this.queryParams)
      }
    },

    // Xử lý khi ấn nút phân trang thêm file
    handlePageClickPage(pageNumber, pageSize) {
      this.queryParams.pageNumber = pageNumber
      this.queryParams.pageSize = pageSize
      this.fetchDownloadFile(this.queryParams)
    },

    // Lấy danh sách cấp giấy chứng nhận kiểm định
    async fetchDataInspectionSafety(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_INSPECTION_SAFETY, {
        params: urlQuery,
      })
      this.dataTable = data.data.pageLists
      this.totalRecord = data.data.totalRecord
      this.$hideLoading()
    },

    // Danh sách combobox doanh nghiệp
    async fetchComboboxBusiness() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMBOBOX_BUSINESS)
      this.dataComboboxBusiness = data?.data
    },

    // Mở modal duyệt cấp phép kiểm định
    showAcceptAction(id) {
      this.licenseAccreditationId = id
      this.$bvModal.show(this.modalAction)
    },

    // Mở modal từ chối duyệt cấp phép kiểm định
    showRejectAction(id) {
      this.licenseAccreditationId = id
      this.$bvModal.show(this.modalReject)
    },

    // Mở modal gia hạn giấy phép kiểm định
    showExtendLicense(id) {
      this.licenseAccreditationId = id
      this.$bvModal.show(this.modalExtendLicense)
    },

    // Mở modal cấp lại giấy phép kiểm định
    showRenewLicense(id) {
      this.licenseAccreditationId = id
      this.$bvModal.show(this.modalRenewLicense)
    },

    // Mở modal thu hồi giấy phép kiểm định
    showRevokeLicense(id) {
      this.licenseAccreditationId = id
      this.$bvModal.show(this.modalRevokeLicense)
    },

    // Duyệt cấp phép kiểm định
    async handleAcceptLicense(val) {
      const model = {
        ...val,
        licenseAccreditationId: this.licenseAccreditationId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.ACCEPT_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Duyệt thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalAction)
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Duyệt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Từ chối duyệt cấp phép
    async handleReject(val) {
      const model = {
        ...val,
        licenseAccreditationId: this.licenseAccreditationId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REJECT_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Từ chối duyệt thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalReject)
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Từ chối duyệt không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // gia hạn duyệt kiểm định
    async handleExtendLicense(val) {
      const model = {
        ...val,
        licenseAccreditationId: this.licenseAccreditationId,
      }

      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.EXTEND_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gia hạn thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalReject)
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gia hạn không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Cấp lại duyệt kiểm định
    async handleRenewLicense(val) {
      const model = {
        ...val,
        licenseAccreditationId: this.licenseAccreditationId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.RENEW_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cấp lại thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalReject)
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Cấp lại không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // thu hồi giấy kiểm định
    async handleRevoke(val) {
      const model = {
        ...val,
        licenseAccreditationId: this.licenseAccreditationId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REVOKE_LICENSE, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thu hồi thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.modalRevokeLicense)
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Thu hồi không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    showAcceptPaper() {
      this.$bvModal.show(this.modalAcceptPaper)
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataInspectionSafety(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataInspectionSafety(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataInspectionSafety(this.urlQuery)
      }
    },

    // lấy danh sách khi lọc theo trạng thái
    filterStatus(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = val
        this.fetchDataInspectionSafety(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.status = ''
        this.fetchDataInspectionSafety(this.urlQuery)
      }
    },
    // Lấy dan hsachs lọc theo giấy phép
    filterBusiness(val) {
      if (val !== null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = val
        this.fetchDataInspectionSafety(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.bussinessId = ''
        this.fetchDataInspectionSafety(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_INSPECTION_SAFETY, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataInspectionSafety(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
#inspectionSafetyId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }
}
</style>
