<template>
  <b-modal
    :id="id"
    size="md"
    title="Duyệt cấp phép chứng nhận kiểm định"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    @ok="handleAcceptLicense"
    @hidden="resetState"
  >
    <div class="modal-accept">
      <validation-observer ref="rule">
        <b-form class="content-form">
          <b-form-group>
            <label for="InputHelp">Quyết định số<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="customCode"
            >
              <b-form-input
                id="InputHelp"
                v-model="dataLicense.numberDecision"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Ngày ngày cấp<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required: `Ngày hiệu lực là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataLicense.dateLicense"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Ngày hết hiệu lực<span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required: `Ngày hết hiệu lực là bắt buộc`}"
            >
              <date-time-picker
                v-model="dataLicense.dateExpired"
                :disabledInput="true"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>Số giấy chứng nhận</label>
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
              :custom-messages="{required: `Số giấy chứng nhận là bắt buộc`}"
            >
              <b-form-input
                v-model="dataLicense.numberLicense"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BRow, BCol, BFormGroup, BModal, BFormInput, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormTextarea,
    BButton,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      customCode: {
        required: 'Quyết định số là bắt buộc',
      },
      dataLicense: {
        numberDecision: '',
        dateLicense: '',
        dateExpired: '',
        numberLicense: '',
      },
    }
  },
  methods: {
    handleAcceptLicense(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          const dateEnd = new Date(this.dataLicense.dateExpired).getTime()
          const datestart = new Date(this.dataLicense.dateLicense).getTime()

          if (datestart > dateEnd) {
            this.$root.$bvToast.toast('Ngày hết hiệu lực không nhỏ hơn ngày hiệu lực', {
              title: 'Thông báo',
              variant: 'danger',
              toaster: this.$toastPosition,
              solid: true,
            })
          } else {
            this.$emit('handleAcceptLicense', this.dataLicense)
          }
        }
      })
    },
    resetState() {
      this.dataLicense = {
        numberDecision: '',
        dateLicense: '',
        dateExpired: '',
        numberLicense: '',
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .action {
    display: flex;
    justify-content: end;
  }
}
</style>
