var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"size":"md","title":"Duyệt cấp phép chứng nhận kiểm định","cancel-title":"Hủy bỏ","cancel-variant":"outline-primary","ok-title":"Lưu","centered":""},on:{"ok":_vm.handleAcceptLicense,"hidden":_vm.resetState}},[_c('div',{staticClass:"modal-accept"},[_c('validation-observer',{ref:"rule"},[_c('b-form',{staticClass:"content-form"},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Quyết định số"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":_vm.customCode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"InputHelp","state":errors.length > 0 ? false:null},model:{value:(_vm.dataLicense.numberDecision),callback:function ($$v) {_vm.$set(_vm.dataLicense, "numberDecision", $$v)},expression:"dataLicense.numberDecision"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày ngày cấp"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":{required: "Ngày hiệu lực là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataLicense.dateLicense),callback:function ($$v) {_vm.$set(_vm.dataLicense, "dateLicense", $$v)},expression:"dataLicense.dateLicense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Ngày hết hiệu lực"),_c('span',{staticClass:"required"},[_vm._v("(*)")])]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":{required: "Ngày hết hiệu lực là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-time-picker',{attrs:{"disabledInput":true,"state":errors.length > 0 ? false:null},model:{value:(_vm.dataLicense.dateExpired),callback:function ($$v) {_vm.$set(_vm.dataLicense, "dateExpired", $$v)},expression:"dataLicense.dateExpired"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',[_vm._v("Số giấy chứng nhận")]),_c('validation-provider',{attrs:{"name":"name","rules":"required","custom-messages":{required: "Số giấy chứng nhận là bắt buộc"}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.dataLicense.numberLicense),callback:function ($$v) {_vm.$set(_vm.dataLicense, "numberLicense", $$v)},expression:"dataLicense.numberLicense"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }