export default {
  LIST_COMBOBOX_BUSINESS: 'Bussiness/get-combobox-bussiness',
  LIST_INSPECTION_SAFETY: 'LicenseAccreditation/list-license-accreditation',
  ACCEPT_LICENSE: 'LicenseAccreditation/approve-license-accreditation',
  REJECT_LICENSE: 'LicenseAccreditation/reject-license-accreditation',
  EXTEND_LICENSE: 'LicenseAccreditation/extend-license-accreditation',
  RENEW_LICENSE: 'LicenseAccreditation/renew-license-accreditation',
  REVOKE_LICENSE: 'LicenseAccreditation/revoke-license-accreditation',
  LIST_FILE_DOWNLOAD: 'LicenseAccreditation/list-license-accreditation-file',
  DELETE_INSPECTION_SAFETY: 'LicenseAccreditation/delete-licenseAccreditations',
}
